<template>
  <div class="rss_component">
    <bio-onboarding-modal v-if="!getProfile.onboarding_data['bio_link']"></bio-onboarding-modal>
    <transition name="slide">
      <div class="container">
        <modal-bio-preview :self="this"></modal-bio-preview>
        <div class="rss_component_inner">
          <div class="component_head d-flex align-items-center">
            <div class="head_left">
              <ui-button
                :type="'add'"
                :color="'#ffffff'"
                :bgColor="'#3988fe'"
                :text="'New Bio Link'"
                :icon="'fal fa-plus'"
                :iconPosition="'right'"
                :iconBackground="'rgba(31, 33, 110, 0.35)'"
                :iconBorderRadius="'50%'"
                :pulseEffect="
                  getProfile.onboarding == false &&
                  getProfile.onboarding_data['bio_link'] == false
                "
                v-if="getProfile.policy.can_add_bio_links"
                v-on:click="createBioLink()"
              />
            </div>
          </div>

          <div class="white_box">
            <div class="box_inner">
              <div class="box_head d-flex align-items-center">
                <div class="left">
                  <h2>Bio Links</h2>
                  <p class="">
                    Optimize your internet presence, whether you’re a blogger,
                    an artist or run a content platform. You get one link to
                    house all the content you’re driving followers to. Share
                    that link anywhere, like your Instagram bio, Facebook posts
                    or Twitch profile.
                  </p>
                </div>

                <div class="ml-auto right more_width_500 d-flex align-items-center justify-content-end">

                  <div class="ml-2 toggle_btn_link with_border">
                    <div class="toggle_btn_inner">
                      <a
                        @click.prevent="showBioLinkArchive(false)"
                        class="btn_link"
                        :class="{ active_tab: !payload.archive }"
                      >
                        Active Bio links
                      </a>
                      <a
                        @click.prevent="showBioLinkArchive(true)"
                        class="btn_link"
                        :class="{ active_tab: payload.archive }"
                      >
                        Archived Bio links
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="box_head d-flex align-items-center pt-0">
                <div class="head_right d-flex align-items-center justify-content-end w-100">
                  <div class="col-md-6">
                    <div class="mr-2 search_input_block no_animation" :class="{ active_input: payload.search }">
                      <input placeholder="Search bio links by name or shorten id" :value="payload.search" @input="debounceSearch"
                             type="text"
                             class="bio_input_search" @keyup.enter="showBio()"/>
                      <i class="fal fa-search" @click.prevent="showBio()"></i>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="right">
                      <b-dropdown right
                                  class="ml-2 d-inline-block dropdown-menu-right default_style_dropdown checkbox_dropdown results_dropdown"
                                  :no-caret="true"
                                  ref="resultsDropdown">
                        <div class="dropdown_header rounded_shape d-flex align-items-center  "
                             slot="button-content">
                          <span class="text">{{ resultsText }}</span>
                          <span class="arrow_icon ml-auto">
                                                <i class=" fal fa-angle-down ml-3"></i>
                                             </span>
                        </div>
                        <ul class="inner results_filter_dropdown">
                          <li :key="index" v-for="(item,index) in [10, 20, 30, 50, 100]" class="list_item_li"
                              @click="results(item)">
                            {{ item }} Results
                            <i v-if="payload.limit==item" class="far fa-check"></i>
                          </li>
                        </ul>
                      </b-dropdown>
                    </div>
                  </div>
                </div>
              </div>
              <div class="box_content">
                <div class="table_container">
                  <table class="table list-content">
                    <thead class="list-content-head filter_head">
                    <tr>
                      <th>Status</th>
                      <th class="text-center" style="width: 220px">Name</th>
                      <th class="text-left" style="width: 260px">URL</th>
                      <th class="text-center">Total Visitors</th>
                      <th class="text-center">Created</th>
                      <th style="width: 200px; text-align: right">Actions</th>
                    </tr>
                    </thead>
                    <tbody class="list-content-body">
                    <tr v-if="getBioLinkLoaders.retrieve">
                      <td colspan="7">
                        <clip-loader
                          :size="size"
                          :color="color"
                        ></clip-loader>
                      </td>
                    </tr>

                    <tr
                      :class="{ overlay_item_archived: bio.archive }"
                      v-else-if="getBioLinkItems.length > 0"
                      v-for="(bio, index) in getBioLinkItems"
                      :key="bio._id"
                    >
                      <td style="text-align: center">
                          <span
                            class="bio_status status_incomplete hidden float-left"
                            v-if="bio.state === 'draft'"
                          >Incomplete</span
                          >
                        <span class="bio_status status_complete hidden float-left" v-else
                        >Active</span
                        >
                      </td>
                      <td style="text-align: center">
                        {{ bio.name }}
                      </td>

                      <td
                        :class="'bio_link_' + index"
                        style="text-align: left"
                      >
                        <div v-if="bio.edit_shorten_url === true" class="bio-link-editor d-flex align-items-center">
                          <span v-if="bio.domain" class="editing-link">{{bio.domain.url }}</span>
                          <span v-else class="editing-link">https://{{ bio.shorten_domain }}/{{bio.domain.url }}</span>
                          <div class="inline-editor">
                            <input maxlength="50" type="text" @keyup.enter="editBioShortenURLAction(bio, index)" v-model="bio.edit_shorten_url_value" placeholder="Enter Shorten URL here..."/>
                            <span class="icon red" @click="closeBioShortenURL(bio, index)"><i class="fal fa-times"></i></span>
                            <clip-loader v-if="bio.edit_shorten_url_loader" class="icon" style="right: 30px" :size="'10px'" :color="color"></clip-loader>
                            <span v-else class="icon green" @click.prevent="editBioShortenURLAction(bio, index)"><i class="far fa-check"></i></span>
                          </div>
                        </div>
                        <div
                          v-else
                          class="favicon_img"
                          :style="getBioLinkFavicon(bio.favicon)"
                        >
                          <a
                            v-if="bio.domain"
                            class="replug_link_generated truncate ml-4"
                            :title="bio.domain.url + bio.shorten_url"
                            :href="bio.domain.url + bio.shorten_url"
                            target="_blank"
                          >
                            {{ bio.domain.url }}{{ bio.shorten_url }}
                          </a>
                          <a
                            v-else
                            class="replug_link_generated truncate ml-4"
                            :title="
                                'https://' +
                                bio.shorten_domain +
                                '/' +
                                bio.shorten_url
                              "
                            :href="
                                'https://' +
                                bio.shorten_domain +
                                '/' +
                                bio.shorten_url
                              "
                            target="_blank"
                          >
                            https://{{ bio.shorten_domain }}/{{
                              bio.shorten_url
                            }}
                          </a>
                        </div>
                        <span class="copy_link">
                            <i
                              v-tooltip="'Copy Link'"
                              v-clipboard:copy="copyBioLink(bio)"
                              v-clipboard:success="onCopy"
                              v-clipboard:error="onError"
                              class="fal fa-clipboard"
                            ></i>
                            <span
                              class="icon"
                              @click="editBioLinkShortenURL(bio, index)"
                            >
                              <i class="far fa-pencil"></i>
                            </span>
                          </span>
                      </td>

                      <td style="text-align: center">
                        <p v-if="!bio.fetchStats">{{ bio.total_visitors }}</p>
                        <skeleton v-else></skeleton>
                      </td>
                      <td style="text-align: center">
                        {{ bio.created_at | relativeCaptilize }}
                      </td>
                      <td class="text-right" v-if="!getBioLinks.archive">
                        <template v-if="!bio.archive">
                          <b-dropdown
                            ref="dropdownActionMenu"
                            right
                            offset="10"
                            class="
                                d-inline-block
                                table_action_dropdown
                                dropdown-menu-right
                                default_style_dropdown
                              "
                            :visible="showDropdown"
                            :no-caret="true"
                          >
                            <!--d-flex align-items-center-->
                            <div
                              class="dropdown_header no_arrow text-right"
                              slot="button-content"
                            >
                                <span class="text">
                                  <i class="fal fa-ellipsis-h fw-500"></i>
                                </span>
                            </div>
                            <ul class="clear inner">
                              <li class="list_item">
                                <a
                                  @click.prevent="editBioLink(bio)"
                                  target="_blank"
                                  href=""
                                >
                                  <i
                                    class="far fa-pencil"
                                    aria-hidden="true"
                                  ></i>
                                  Edit
                                </a>
                              </li>
                              <li class="list_item">
                                <a
                                  target="_blank"
                                  href=""
                                  @click.prevent="
                                      isArchiveBioAction(
                                        bio,
                                        index,
                                        !bio.archive
                                      )
                                    "
                                  data-cy="b-archive"
                                >
                                  <i
                                    class="far fa-recycle"
                                    aria-hidden="true"
                                  ></i
                                  >{{ bio.archive ? 'Unarchive' : 'Archive' }}
                                </a>
                              </li>
                              <li class="list_item">
                                <a @click="previewBio(bio)">
                                  <i
                                    class="far fa-eye"
                                    aria-hidden="true"
                                  ></i>
                                  Preview
                                </a>
                              </li>

                              <li class="list_item">
                                <template v-if="bio.qr_code_url">
                                  <a @click="renderQRCode(bio.qr_code_url)">
                                    <i
                                      class="far fa-qrcode"
                                      aria-hidden="true"
                                    ></i>
                                    Download QR Code
                                  </a>
                                </template>
                                <template v-else>
                                  <a
                                    @click="
                                        createBioQRCode({
                                          url:
                                            bio.shorten_domain +
                                            '/' +
                                            bio.shorten_url,
                                          slug: bio.shorten_url,
                                        })
                                      "
                                  >
                                    <i
                                      class="far fa-qrcode"
                                      aria-hidden="true"
                                    ></i>
                                    Download QR Code
                                  </a>
                                </template>
                              </li>

                              <li class="list_item">
                                <a @click="deleteBioLink(bio, index)">
                                  <i
                                    class="far fa-trash"
                                    aria-hidden="true"
                                  ></i>
                                  Remove
                                </a>
                              </li>
                            </ul>
                          </b-dropdown>

                          <span
                            class="actions text-center"
                            style="margin-left: 5px"
                          >
                            </span>
                        </template>
                        <template v-else>
                          <div class="actions">
                            <i v-tooltip="'Unarchive'" class="far fa-recycle "
                               @click.prevent="isArchiveBioAction(bio, index, !bio.archive)"
                               aria-hidden="true" data-cy="b-archive"></i>
                          </div>
                        </template>
                      </td>
                      <td class="text-right actions" v-else>
                        <template>
                          <i
                            v-tooltip="'Unarchive'"
                            class="far fa-recycle"
                            @click.prevent="
                                isArchiveBioAction(
                                  bio,
                                  index,
                                  !getBioLinks.archive
                                )
                              "
                            aria-hidden="true"
                            data-cy="link-unarchive"
                          ></i>
                        </template>
                      </td>
                    </tr>
                    <tr v-else>
                      <td colspan="9" class="">
                        <p class="no-rows"><i class="fab fa-bandcamp" aria-hidden="true"></i>{{
                            getFilteredMessage('bio links', payload.search, 'All Time', payload.archive, [], [], [])
                          }}</p>
                      </td>
                    </tr>
                    <div class="clearfix"></div>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <template
              v-if="
                !getBioLinkLoaders.retrieve &&
                pagination.totalItems > pagination.limit
              "
            >
              <pagination
                :clickedMethod="paginateBioLinks"
                :resultCount="pagination.totalItems"
                :itemsPerPage="pagination.limit"
                :currentPage="pagination.page"
                :totalPagesNumber="pagination.totalPages"
              ></pagination>
            </template>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import Dropdown from '@/ui/Dropdown.vue'
import Pagination from '@/misc/Pagination.vue'
import {bioLinkTypes, userTypes} from '@/state/modules/mutation-types'
import {DARK_PURPLE_LOADER_COLOR} from '@/common/constants'
import BioOnboardingModal from './onboarding-bio-links/BioOnboardingModal'
import {swalDefaultValues} from '@/common/attributes'
import BioPreviewDialog from './dialogs/BioPreviewDialog'
import ConfirmationBox from '@/ui/ConfirmationBox.vue'

export default {
  components: {
    Pagination,
    Dropdown,
    BioOnboardingModal,
    'modal-bio-preview': BioPreviewDialog,
    ConfirmationBox
  },
  data: function () {
    return {
      showDropdown: false,
      size: '20px',
      color: DARK_PURPLE_LOADER_COLOR,
      debounce: null,
      resultsText: 'Results Per Page',
      payload: {
        archive: false,
        search: '',
        page: 1,
        limit: 10,
        sort: 'created_desc'
      },
      pagination: {}
    }
  }
  ,
  async created () {
    const params = this.getParamsFromURL()
    this.payload.search = params.search
    this.payload.archive = params.archive
    this.payload.page = params.page
    this.payload.limit = params.limit
    if (this.payload.search && this.payload.search.length > 0) this.payload.page = 1
    if (this.payload.limit && this.payload.limit > 10) {
      this.resultsText = this.payload.limit + ' Results'
      this.payload.page = 1
    }
    await this.fetchLinks()
    await this.fetchDomains()
    await this.fetchPixels()
  }
  ,
  mounted () {
    if (!this.getProfile.triggers.bio_links_onboarding) {
      setTimeout(() => {
        this.$bvModal.show('bioLinkOnBoarding')
      }, 500)
    }
  }
  ,
  beforeDestroy () {}
  ,

  computed: {
    ...
      mapGetters([
        'getBioLinkAdd',
        'getBioLinkItems',
        'getBioLinkLoaders',
        'getBioLinks',
        'getBioLinkPagination',
        'getModalData',
      ]),
  }
  ,

  methods: {
    ...
      mapActions([
        'deleteBio',
        'createBioQRCode',
        'renderQRCode',
        'fetchDomains',
        'showBio',
        'fetchBioLinkAnalytics',
        'archiveBioLink',
        'fetchPixels'
      ]),
    getBioLinkFavicon (favicon) {
      if (favicon) {
        return 'background: url(' + favicon + ') center center / cover;'
      } else {
        return (
          'background: url(' +
          'https://storage.googleapis.com/replug-assets/web_app/b8b4396d-152e-43be-8314-fd5d5347d57b.jpg' +
          ') center center / cover;'
        )
      }
    },
    async fetchLinks () {
      const pagination = await this.showBio(this.payload)
      this.setBioLinksPagination(pagination)
      await this.fetchBioLinkAnalytics()
    },
    createBioLink () {
      this.$store.commit(bioLinkTypes.RESET_BIO_LINK_ITEM)
      this.$store.commit(bioLinkTypes.RESET_BIO_CONTENT_SECTION_ADD)
      this.$store.commit(bioLinkTypes.SET_BIO_ADD_IS_CREATING_BIO, true)
      this.$router.push({name: 'select_content'})
    }
    ,
    editBioLink (bio) {
      this.getBioLinkAdd = bio
      this.$store.commit(bioLinkTypes.SET_BIO_ADD_VALUE, bio)
      this.$router.push({name: 'select_content'})
    }
    ,
    previewBio (bio) {
      this.$store.commit(bioLinkTypes.SET_BIO_ADD_VALUE, bio)
      this.$bvModal.show('modal-bio-preview')
    }
    ,
    copyBioLink (bio) {
      return `https://${bio.shorten_domain}/${bio.shorten_url}`
    }
    ,
    editBioLinkShortenURL (bio, index) {
      this.$set(bio, 'edit_shorten_url', true)
      this.$set(bio, 'edit_shorten_url_value', '')
      this.$set(bio, 'edit_shorten_url_loader', false)
    }
    ,
    async editBioShortenURLAction (bio, index) {
      if (
        bio.edit_shorten_url_value &&
        bio.edit_shorten_url_value.trim().length > 0
      ) {
        this.$set(bio, 'edit_shorten_url_loader', true)
        let res = await this.$store.dispatch('bioShortenURLUpdate', {
          _id: bio._id,
          slug: bio.edit_shorten_url_value,
        })
        if (res.data && res.data.status) {
          bio.shorten_url = bio.edit_shorten_url_value
          this.$set(bio, 'edit_shorten_url', false)
          this.$set(bio, 'edit_shorten_url_value', '')
          this.$set(bio, 'edit_shorten_url_loader', false)
          return
        }
        if (res.data && res.data.status === false) {
          this.$set(bio, 'edit_shorten_url_loader', false)
          this.$store.dispatch('toastNotification', {
            message: res.data.message,
            type: 'error',
          })
          return
        }
        this.$store.dispatch('toastNotification', {
          message: 'An error occurred, please try again.',
          type: 'error',
        })
      }
    }
    ,
    closeBioShortenURL (bio, index) {
      this.$set(bio, 'edit_shorten_url', false)
    }
    ,
    deleteBioLink (bio, index) {
      let confirmation = swalDefaultValues()
      var self = this
      swal(confirmation, function (isConfirm) {
        if (isConfirm) {
          self.deleteBio({bioId: bio._id, index: index})
        } else {
          swal()
        }
      })
    }
    ,
    async showBioLinkArchive (value) {
      if (!this.getBioLinkLoaders.retrieve) {
        this.$router.replace({query: {...this.$route.query, archive: value}})
        this.$router.replace({query: {...this.$route.query, page: 1}})
        this.payload.archive = value
        this.payload.page = 1
        await this.fetchLinks()
      }
    }
    ,
    async paginateBioLinks (page) {
      this.$router.replace({query: {...this.$route.query, page: page}})
      this.payload.page = page
      this.pagination.page = page
      await this.fetchLinks()
    }
    ,
    isArchiveBioAction (bio, index, archive_status) {
      this.$store.commit(userTypes.SET_MODAL_DATA, {
        bio_id: bio._id,
        user_id: bio.user_id,
        status: archive_status,
        index: index,
      })
      this.archiveBioLink()
    },
    debounceSearch (event) {
      this.$router.replace({query: {...this.$route.query, search: event.target.value}})
      this.$router.replace({query: {...this.$route.query, page: 1}})
      this.payload.search = event.target.value
      this.payload.page = 1
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.fetchLinks()
      }, 600)
    },
    async results (value) {
      this.resultsText = value + ' Results'
      this.$refs.resultsDropdown.hide()
      this.$router.replace({query: {...this.$route.query, limit: value}})
      this.$router.replace({query: {...this.$route.query, page: 1}})
      this.payload.limit = value
      this.payload.page = 1
      await this.fetchLinks()
    },
    setBioLinksPagination (pagination) {
      this.pagination = {
        page: pagination.current_page,
        totalItems: pagination.total,
        limit: pagination.per_page,
        totalPages: pagination.last_page
      }
    }
  }
}
</script>
<style lang="less">
.bio_input_search {
  max-width: 400px !important;
  width: 400px !important;
}

.bio_status {
  padding: 2px 8px;
  margin-left: 5px;
  border-radius: 4px;
  color: #fff;
}

.status_incomplete {
  background: #ffc107;
}

.status_complete {
  background: #11b50f;
}

.replug_link_generated {
  cursor: pointer;
}

.truncate {
  max-width: 195px;
  float: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.copy_link {
  margin-left: 13px;
  display: none;
}

.modal-bio-preview .modal-dialog {
  max-width: 390px;
}

@-moz-document url-prefix() {
  .default_style_dropdown .dropdown-menu li.list_item a {
    font-size: 13px;
  }
}

.favicon_img {
  width: 20px !important;
  height: 20px !important;
}
</style>
