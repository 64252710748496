<template>

    <div>
<!--        <div class="img">-->
<!--            <img src="https://storage.googleapis.com/replug-assets/web_app/a471826e-6d8c-43b8-8b1d-49415dc2e444.jpg" alt="">-->
<!--        </div>-->
        <h2>Share your bio with the world</h2>
        <p>When you are done setting up your bio, add your unique URL to your social media and share what matters most to you!</p>
    </div>

</template>

<script>
    import { mapActions, mapGetters } from 'vuex'

    export default ({

        components: {

        }

    })
</script>
