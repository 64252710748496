<template>
  <div>
    <!--        <div class="img">-->
    <!--            <img src="https://storage.googleapis.com/replug-assets/web_app/3d908077-4e5a-4a7c-ba99-95c924d4fa20.jpg" alt="">-->

    <!--        </div>-->
    <h2>Welcome to Bio links</h2>
    <p>Offer multiple choices to your followers when they click on your bio link. You can share content, social
      media profiles or any import links with just one url</p>
  </div>
</template>

<script>

export default ({

  components: {}

})
</script>
