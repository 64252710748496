<template>
  <b-modal modal-class="modal_basic modal-bio-preview" id="modal-bio-preview" hide-footer hide-header>
    <img @click="$bvModal.hide('modal-bio-preview')" class="close_modal" src="/assets/img/cross.svg" alt="">
    <div class="basic_form modal_content">
            <bio-preview></bio-preview>
    </div>
  </b-modal>
</template>
<script>
export default ({
  components: {
    'bio-preview': () => import('@/views/pages/bio-link/widgets/snippets/BioPreview'),
  }
})
</script>
