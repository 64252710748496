<template>

    <div>
    <!--        <div class="img">-->
    <!--            <img src="https://storage.googleapis.com/replug-assets/web_app/e9c74245-3691-4aef-8a61-53d422f6e6d7.jpg" alt="">-->

    <!--        </div>-->
    <h2>Use blocks for your bio links</h2>
    <p>You can import or add content to a block. Move blocks around to customize your link as you wish.</p>
    </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default ({

  components: {

  }

})
</script>
