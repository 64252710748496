<template>
  <b-modal modal-class="modal_basic bioLinkOnBoarding" id="bioLinkOnBoarding" hide-footer centered
           hide-header>
    <!--<img @click="$bvModal.hide('bioLinkOnBoarding')" class="close_modal" src="/img/cross.svg" alt="">-->

    <div class="basic_form modal_content">

      <div class="heading">
        <!--<h3>Create Brand</h3>-->
      </div>
      <transition name="slide-fade" mode="out-in">
        <div class="onboarding-content step-one">
          <BioOnboardingYoutubeSection/>
          <first-step v-if="steps === 1"></first-step>
          <second-step v-else-if="steps === 2"></second-step>
          <third-step v-else-if="steps === 3"></third-step>
        </div>
      </transition>

      <div class="btn_block d-flex align-items-center ">
        <div class="btn_left ">
          <ul class="bar-list">
            <li :class="{'active' : steps === 1}"></li>
            <li :class="{'active' : steps === 2}"></li>
            <li :class="{'active' : steps === 3}"></li>
          </ul>
        </div>
        <div class="btn_right text-right ml-auto">
          <button v-if="steps !== 1" @click="moveSteps('back')" type="button"
                  class=" btn---cta light-blue btn-round  btn-bold ">
            <span>Back</span>
          </button>
          <button v-if="steps === 3" @click="triggerAndCreateBioLink()"
                  class="ml-2 btn---cta btn-blue with-shadow btn-round   btn-bold">
            <span>Create First Link</span>
          </button>
          <button v-else @click="moveSteps('next')"
                  class="ml-2 btn---cta btn-blue with-shadow btn-round   btn-bold">
            <span>Next</span>
          </button>

        </div>

      </div>

      <div class="text-center">
                <span class="dont_show d-inline-block" @click="triggerDontShow">
                    Don't show again the dailog box.
                </span>
      </div>

    </div>
  </b-modal>

</template>

<script>
import FirstStep from './steps/BioFirstStep'
import SecondStep from './steps/BioSecondStep'
import ThirdStep from './steps/BioThirdStep'
import { bioLinkTypes } from '@/state/modules/mutation-types'

export default ({
  data () {
    return {
      steps: 1
    }
  },
  components: {
    FirstStep,
    SecondStep,
    ThirdStep,
    BioOnboardingYoutubeSection: () => import('./BioOnboardingYoutubeSection')
  },
  methods: {
    moveSteps (type) {
      if (type === 'next') {
        if (this.steps < 3) {
          this.steps += 1
        }
      } else {
        if (this.steps > 1) {
          this.steps -= 1
        }
      }
      console.debug(type, this.steps, 'testttt')
    },
    triggerAndCreateBioLink () {
      this.$store.dispatch('storeTrigger', 'bio_links_onboarding')
      this.$store.commit(bioLinkTypes.RESET_BIO_LINK_ITEM)
      this.$store.commit(bioLinkTypes.RESET_BIO_CONTENT_SECTION_ADD)
      this.$store.commit(bioLinkTypes.SET_BIO_ADD_IS_CREATING_BIO, true)
      this.$router.push({ name: 'select_content' })
    },
    triggerDontShow () {
      this.$store.dispatch('storeTrigger', 'bio_links_onboarding')
      this.$store.commit(bioLinkTypes.RESET_BIO_LINK_ITEM)
      this.$store.commit(bioLinkTypes.RESET_BIO_CONTENT_SECTION_ADD)
      this.$store.commit(bioLinkTypes.SET_BIO_ADD_IS_CREATING_BIO, true)
      this.$bvModal.hide('bioLinkOnBoarding')
    }
  }
})
</script>

<style scoped lang="less">
.dont_show {
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  color: #3988fe;

  &:hover {
    text-decoration: underline;
  }
}

.bioLinkOnBoarding {
  .modal-dialog {
    background: #0d44fb !important;

    .modal-content {
      .modal-body {
        padding: 10px !important;
      }
    }

    .modal-body {
      padding: 10px !important;
    }
  }
}

</style>
